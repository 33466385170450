<template>
  <div class="author-panel">
    <div class="white-block white-block-extra-padding">
      <div class="white-block-title">
        <h1>{{ t("change_password.title") }}</h1>
      </div>

      <div v-if="changedSuccessfully">
        <p>
          <i class="fas fa-key" aria-hidden="true"></i>
          {{ t("change_password.successfully_changed") }}
        </p>
      </div>
      <div v-else>
        <div class="input-wrap">
          <InputPassword
            ref="currentPwdInput"
            :label="t('change_password.current_password')"
            @on-password-changed="(p) => (currentPwd = p)"
          />

          <InputPassword
            ref="newPwdInput"
            :label="t('change_password.new_password')"
            @on-password-changed="(p) => (newPwd = p)"
          />

          <InputPassword
            ref="confirmPwdInput"
            :label="t('change_password.confirm_password')"
            @on-password-changed="(p) => (confirmPwd = p)"
          />

          <div role="alert" v-if="errorText" class="error">
            <i class="fas fa-exclamation-circle"></i>
            {{ errorText }}
          </div>

          <button class="af-button" @click="changePasswordClicked">
            {{ t("change_password.change_password") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import InputPassword from "modules/ui/InputPassword.vue";

const MIN_PASSWORD_LENGTH = 9;

export default {
  name: "ChangePassword",
  components: {
    InputPassword,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t("change_password.title"),
    });

    return {
      t,
      loadData: async () => {},
      currentPwd: ref(""),
      newPwd: ref(""),
      confirmPwd: ref(""),
      errorText: ref(""),
      changedSuccessfully: ref(false),
    };
  },
  methods: {
    checkForm() {
      if (!this.currentPwd) {
        this.errorText = this.t("change_password.error.no_current_pwd");
        this.$refs.currentPwdInput.inputField.focus();
        return false;
      }

      if (!this.newPwd) {
        this.errorText = this.t("change_password.error.no_new_pwd");
        this.$refs.newPwdInput.inputField.focus();
        return false;
      }

      if (!this.confirmPwd) {
        this.errorText = this.t("change_password.error.no_confirm_pwd");
        this.$refs.confirmPwdInput.inputField.focus();
        return false;
      }

      if (
        this.currentPwd.length < MIN_PASSWORD_LENGTH ||
        this.newPwd.length < MIN_PASSWORD_LENGTH ||
        this.confirmPwd.length < MIN_PASSWORD_LENGTH
      ) {
        this.errorText = this.t("change_password.error.pwd_too_short_x", {
          min: MIN_PASSWORD_LENGTH,
        });

        if (this.currentPwd.length < MIN_PASSWORD_LENGTH) {
          this.$refs.currentPwdInput.inputField.focus();
        } else if (this.newPwd.length < MIN_PASSWORD_LENGTH) {
          this.$refs.newPwdInput.inputField.focus();
        } else if (this.confirmPwd.length < MIN_PASSWORD_LENGTH) {
          this.$refs.confirmPwdInput.inputField.focus();
        }

        return false;
      }

      if (this.newPwd != this.confirmPwd) {
        this.errorText = this.t(
          "change_password.error.different_new_and_confirm"
        );
        this.$refs.confirmPwdInput.inputField.focus();
        return false;
      }

      this.errorText = "";
      return true;
    },
    async changePasswordClicked() {
      if (!this.checkForm()) {
        return;
      }

      var r = await this.$store.dispatch("user/changePassword", {
        oldPassword: this.currentPwd,
        newPassword: this.newPwd,
      });

      switch (r.status) {
        case "SUCCESS": {
          this.errorText = "";
          this.changedSuccessfully = true;
          break;
        }
        case "INVALID_PASSWORD": {
          this.errorText = this.t("change_password.error.invalid_password");
          break;
        }
        case "TOO_LONG": {
          this.errorText = this.t("change_password.error.pwd_too_long");
          break;
        }
        case "TOO_SHORT": {
          this.errorText = this.t("change_password.error.pwd_too_short");
          break;
        }
        case "UNKNOWN":
        default: {
          this.errorText = this.t("change_password.error.server_error");
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 25px;
}

.author-panel {
  max-width: 1000px;
  margin: auto;
}

.white-block-title {
  padding-bottom: 20px;
}

.input-wrap {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.error {
  margin: 15px auto;
  color: red;
  padding: 10px 0;
}
</style>
